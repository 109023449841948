/** @format */

import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  min-height: 692px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  z-index: 0;
  overflow: hidden;
  background: #efefef;
`;

export const FormWrap = styled.div`
  height: 100%;
  width: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 50px;
  @media screen and (max-width: 600px) {
    height: 80%;
    width: 500px;
  }
`;

export const Icon = styled(Link)`
  text-decoration: none;
  text-align: center;
  color: #1b2b44;
  font-weight: 700;
  font-size: 32px;
`;

export const FormContent = styled.div`
  height: 100%;
  display: flex;
  margin-top: 30px;
  margin: auto;
  flex-direction: column;
  justify-content: center;

  @media screen and (max-width: 480px) {
    padding: 10px;
  }
`;

export const Form = styled.form`
  background: #fff;
  max-width: 20rem;
  height: 100%;
  width: 100%;
  display: grid;

  padding: 1.5rem 2rem;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 3px;

  @media screen and (max-width: 840px) {
    max-width: 17rem;
  }
`;

export const FormH1 = styled.h1`
  margin-bottom: 30px;

  font-size: 25px;
  font-weight: 600;
`;

export const FormLabel = styled.label`
  margin-bottom: 8px;
  font-size: 14px;
`;

export const FormInput = styled.input`
  padding: 0.25rem 0.65rem;
  margin-bottom: 25px;
  border: none;
  font-size: 0.8rem;
  background-color: #ebf6ff;
  width: 100%;
  line-height: 1.4rem;
  border-radius: 4px;
`;

export const FormButton = styled.button`
  background: #1561a5;
  padding: 0.25rem;
  border: none;
  border-radius: 4px;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
`;

export const Text = styled.span`
  margin-top: 24px;

  font-size: 13px;
`;
