/** @format */

import React, { useEffect } from 'react';

import emailjs from 'emailjs-com';

import {
  Container,
  Form,
  FormButton,
  FormContent,
  FormH1,
  FormInput,
  FormLabel,
  FormWrap,
  Text,
  Icon,
} from './RegisterUserElements';
import logo from '../../Assets/logopng1.png';

import Footer from '../footer/Footer';

const Signin = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm('service_ljmx4no', 'template_mzjk7tg', e.target, 'user_E353rENksIp0gw9xaEZbJ')
      .then((result) => {
        console.log('SUCCESS!', result.text);
      })
      .catch((error) => console.log(error));

    e.target.reset();
  }

  return (
    <>
      <Container>
        <FormWrap>
          <Icon to='/'>
            <img src={logo} alt='logo' style={{ height: '55px', width: '60px' }} />
          </Icon>
          <FormContent>
            <Form action='#' onSubmit={sendEmail}>
              <FormH1>Book Your Demo!</FormH1>

              <FormLabel htmlFor='for'> Name</FormLabel>
              <FormInput type='text' name='name' required />

              <FormLabel htmlFor='for'> Email</FormLabel>
              <FormInput type='email' name='email' required />

              <FormLabel htmlFor='for'> Phone No.</FormLabel>
              <FormInput type='text' name='phone' required />

              <FormLabel htmlFor='for'> Message</FormLabel>
              <FormInput type='text' name='message' required />

              <FormButton type='submit' value='send'>
                Submit
              </FormButton>
              <Text>
                Once you Submit your info will reach to Livzone. Or you can simply Call on this
                number +91-7999950973
              </Text>
            </Form>
          </FormContent>
        </FormWrap>
      </Container>
      <Footer />
    </>
  );
};

export default Signin;
