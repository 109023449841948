/** @format */

import React from 'react';
import {
  SideBarContainer,
  SidebarWrapper,
  Icon,
  CloseIcon,
  SideBtnwrap,
  SidebarLink,
  SidebarRoute,
  SidebarMenu,
} from './SideBarNavElements';
import { Link as LinkR } from 'react-router-dom';

const SideBarNav = ({ isOpen, toggle }) => {
  return (
    <SideBarContainer isOpen={isOpen} onClick={toggle}>
      <Icon onClick={toggle}>
        <CloseIcon />
      </Icon>

      <SidebarWrapper>
        <SidebarMenu>
          <SidebarLink to='home' onClick={toggle}>
            Home
          </SidebarLink>

          <SidebarLink to='about' onClick={toggle}>
            About
          </SidebarLink>
          <SidebarLink to='product' onClick={toggle}>
            Product
          </SidebarLink>

          <SidebarLink to='contact' onClick={toggle}>
            Contact
          </SidebarLink>
        </SidebarMenu>

        <SideBtnwrap>
          <SidebarRoute to='/register'>Book demo</SidebarRoute>
        </SideBtnwrap>
      </SidebarWrapper>
      <hr
        style={{
          marginTop: '-140px',
          width: '200px',
          marginLeft: 'auto',
          marginRight: 'auto',
          borderTop: '0px',
          borderBottom: '1px solid white',
          opacity: '0.4',
        }}
      />
      <LinkR
        to='/downloads'
        style={{
          textDecoration: 'none',
          fontSize: '20px',
          color: '#43c9e6',
          textAlign: 'center',
          marginTop: '-270px',
          '&:hover': {
            color: '#fff',
          },
        }}>
        Brochures
      </LinkR>
    </SideBarContainer>
  );
};

export default SideBarNav;
