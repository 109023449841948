/** @format */

import React, { useEffect, useState } from 'react';
import './Downloader.css';
import { ProgressBar } from 'react-bootstrap';
import { AiFillCheckCircle } from 'react-icons/ai';
import Axios from 'axios';

const Downloader = ({ files = [], remove }) => {
  return (
    <div className='downloader'>
      <div className='card'>
        <ul className='list-group list-group-flush'>
          {files.map((file, idx) => (
            <DownloadItem key={idx} removeFile={() => remove(file.downloadId)} {...file} />
          ))}
        </ul>
      </div>
    </div>
  );
};

const DownloadItem = ({ name, file, filename, removeFile }) => {
  const [downloadInfo, setDownloadInfo] = useState({
    progress: 0,
    completed: false,
    total: 0,
    loaded: 0,
  });

  useEffect(() => {
    const options = {
      onDownloadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;

        setDownloadInfo({
          progress: Math.floor((loaded * 100) / total),
          loaded,
          total,
          completed: false,
        });
      },
    };

    Axios.get(file, {
      responseType: 'blob',
      ...options,
    }).then(function (response) {
      console.log(response);

      const url = window.URL.createObjectURL(
        new Blob([response.data], {
          type: response.headers['content-type'],
        })
      );

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();

      setDownloadInfo((info) => ({
        ...info,
        completed: true,
      }));

      setTimeout(() => {
        removeFile();
      }, 1000);
    });
  }, [file, filename, removeFile]);

  const formatBytes = (bytes) => `${(bytes / (1024 * 1024)).toFixed(2)} MB`;

  return (
    <li
      className='list-group-item'
      style={{
        background: 'linear-gradient(to left, #606c88, #3f4c6b)',
        color: '#fff',
        padding: '15px',
        borderRadius: '10px',
      }}>
      <div className='row'>
        <div
          className='col-12 d-flex'
          style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
          <div className='d-inline font-weight-bold text-truncate' style={{ fontWeight: '500' }}>
            {name}
          </div>
          <div className='d-inline ml-2'>
            <small>
              {downloadInfo.loaded > 0 && (
                <>
                  <span className='text-success'>{formatBytes(downloadInfo.loaded)}</span>/
                  {formatBytes(downloadInfo.total)}
                </>
              )}

              {downloadInfo.loaded === 0 && <>Initializing...</>}
            </small>
          </div>
          <div className='d-inline ml-2 ml-auto' style={{ justifyContent: 'center' }}>
            {downloadInfo.completed && (
              <span className='text-success' st>
                Completed <AiFillCheckCircle />
              </span>
            )}
          </div>
        </div>
        <div className='col-12 mt-2' style={{ marginTop: '15px' }}>
          <ProgressBar
            style={{
              color: 'white',
              background: 'linear-gradient(to right, #f2709c, #ff9472)',
              borderRadius: '10px',
              paddingLeft: '15px',
              textAlign: 'center',
            }}
            variant='success'
            now={downloadInfo.progress}
            striped={true}
            label={`${downloadInfo.progress}%`}
          />
        </div>
      </div>
    </li>
  );
};

export default Downloader;
