/** @format */

import React, { useEffect } from 'react';

import { FaFileDownload } from 'react-icons/fa';
import Footer from '../../components/footer/Footer';
import useFileDownloader from './useFileDownloader';

// image files
import NewBrochure from '../../Assets/NewBrochure.pdf';
import Brochure from '../../Assets/Brochure.pdf';
import Demo from '../../Assets/Demo.pdf';
import brochure from '../../Assets/brochure.png';
import newbrochure from '../../Assets/newBrochure.png';
import demo from '../../Assets/demo.png';

const files = [
  {
    id: 1,
    name: 'Brochure 1',
    thumb: newbrochure,
    file: NewBrochure,
    filename: 'Livzone_NewBrochure.pdf',
    description: ' Download brochure for more details',
  },
  {
    id: 2,
    name: 'Brochure 2',
    thumb: brochure,
    file: Brochure,
    filename: 'Livzone_Brochure.pdf',
    description: 'You can download our brochures for more details',
  },

  {
    id: 3,
    name: 'Product Demo',
    thumb: demo,
    file: Demo,
    filename: 'Livzone_Product_demo.pdf',
    description: ' Download product demo for more details',
  },
];
const FileDownloadPage = () => {
  const [downloadFile, downloaderComponentUI] = useFileDownloader();
  const download = (file) => downloadFile(file);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div
        style={{
          textAlign: 'center',
          alignItems: 'center',
          padding: '50px',
          background: '#f2f5fa',
        }}>
        <h2 style={{ fontSize: '1.5rem' }}>Our Brochures and Demo</h2>
        <p style={{ marginBottom: '70px', fontSize: '0.8rem' }}>
          Download Livzone brochures and product demo for deep view{' '}
        </p>
        <div
          className='cardWrapper'
          style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '30px' }}>
          {files.map((file) => (
            <div
              className='card'
              style={{
                background: '#fff',
                color: '#1b2b44',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
                borderRadius: '15px',
                // minHeight: '200px',
                padding: '20px',
                boxShadow: ' 0 1px 3px rgba(0, 0, 0, 0.2)',
              }}
              key={file.id}>
                <img
                  style={{
                    width: '100%',
                    minWidth: '170px',
                    maxWidth: '350px',
                    objectFit: 'cover',
                    marginBottom: '10px',
                    borderRadius: '8px',
                  }}
                  alt={file.name}
                  src={file.thumb}
                />
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <h2 style={{ display: 'flex', fontSize: '1rem', marginBottom: '10px' }}>
                  {file.name}
                </h2>
                <p style={{ display: 'flex', fontSize: '0.7rem', textAlign: 'center' }}>
                  {file.description}
                </p>
                <button
                  style={{
                    textDecoration: 'none',
                    border: 'none',
                    backgroundColor: '#117185',
                    color: '#fff',
                    padding: '10px 30px',
                    marginTop: '12px',
                    borderRadius: '50px',
                    cursor: 'pointer',
                  }}
                  className='downButton'
                  onClick={() => download(file)}>
                  Download
                  <FaFileDownload
                    style={{ color: '#fff', marginLeft: '8px', height: '15px', width: '15px' }}
                  />
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
      {downloaderComponentUI}
      <Footer />
    </>
  );
};

export default FileDownloadPage;
