/** @format */

export const homeObjOne = {
  id: 'about',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'About Us',
  headline: 'A Water Ionizer company',
  description:
    'LIVZONE An ISO Certified Company integrates sales and after-sale service together. Our main products are Water Ionizers. All LIVZONE products have passed CE certification ,At the same time LIVZONE products have been certified as Medical Devices Quality Management Systems .Our Motto is to make every one Healthy & Wealthy. We have unique range of products which are suited to everyone’s kitchen both aesthetically and utility wise.',
  buttonLabel: 'Get started',
  imgStart: false,
  img: require('../../Assets/watergirl.jpg'),

  alt: 'Some Watery Img',
  dark: false,
  moveto: 'home',
  linkRoute: false,
  primary: false,
  darkText: true,
};

export const homeObjThree = {
  id: 'product',
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Our Products',
  headline: 'Alkaline Water Ionizer',
  description:
    'LIVZONE Water Industries is pleased to introduce Alkaline Water Ionizers for revitalizing, refreshing, pure clean & healthy Alkaline water for you and your precious family.Alkaline Water Ionizers gives you full control to set mineral level as well as the pH level of water only with just one touch.',
  buttonLabel: 'know More',
  moveto: '/product',
  linkRoute: true,
  imgStart: false,
  img: require('../../Assets/plate11.jpg'),
  alt: 'Some Watery Img',
  dark: true,
  primary: true,
  darkText: false,
};
