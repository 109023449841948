/** @format */

import React from 'react';
import {
  Column1,
  InfoRow,
  Subtitle,
  TextWrapper,
  ImgWrap,
  Img,
  Column2,
  InfoWrapper,
  ProductContainer,
  Heading,
} from './ProductCElements';

const ProductComponent = ({
  img,
  heading,
  alt,
  plate,
  orpValue,
  phRange,
  coating,
  ratedVoltage,
  permitWaterTemperature,
  electrolysisTime,
  alaram,
  dispensing,
  lcdDisplay,
}) => {
  return (
    <ProductContainer>
      <InfoWrapper>
        <InfoRow>
          <Column2>
            <ImgWrap>
              <Img src={img} alt={alt} />
            </ImgWrap>
          </Column2>

          <Column1>
            <TextWrapper>
              <Heading>{heading}</Heading>
              <Subtitle>
                {plate && (
                  <div className='productcom__desc-line'>
                    <strong style={{ fontWeight: '400', fontSize: '0.8rem' , color: "#d7dadb"}}>
                      Electrolytic Plates -
                    </strong>
                    <small> {plate} plates</small>
                  </div>
                )}

                {ratedVoltage && (
                  <div className='productcom__desc-line'>
                    <strong style={{ fontWeight: '400', fontSize: '0.8rem' , color: "#d7dadb"}}>
                      Rated Voltage -
                    </strong>
                    <small> {ratedVoltage} </small>
                  </div>
                )}

                {permitWaterTemperature && (
                  <div className='productcom__desc-line'>
                    <strong style={{ fontWeight: '400', fontSize: '0.8rem' , color: "#d7dadb"}}>
                      {' '}
                      Permit Water Temperature -
                    </strong>
                    <small>
                      {' '}
                      {permitWaterTemperature} <span>&#8451;</span>
                    </small>
                  </div>
                )}

                {coating && (
                  <div className='productcom__desc-line'>
                    <strong style={{ fontWeight: '400', fontSize: '0.8rem' , color: "#d7dadb"}}>
                      Electrode Material -
                    </strong>
                    <small> {coating} </small>
                  </div>
                )}

                {electrolysisTime && (
                  <div className='productcom__desc-line'>
                    <strong style={{ fontWeight: '400', fontSize: '0.8rem' , color: "#d7dadb"}}>
                      Continuous Electrolysis Time -
                    </strong>
                    <small> {electrolysisTime} </small>
                  </div>
                )}

                {phRange && (
                  <div className='productcom__desc-line'>
                    <strong style={{ fontWeight: '400', fontSize: '0.8rem' , color: "#d7dadb"}}> Range -</strong>
                    <small> {phRange} </small>
                  </div>
                )}

                {orpValue && (
                  <div className='productcom__desc-line'>
                    <strong style={{ fontWeight: '400', fontSize: '0.8rem' , color: "#d7dadb"}}>Orp Value -</strong>
                    <small> {orpValue} </small>
                  </div>
                )}
                {lcdDisplay && (
                  <div className='productcom__desc-line'>
                    <strong style={{ fontWeight: '400', fontSize: '0.8rem' , color: "#d7dadb"}}>LCD Display -</strong>
                    <small> {lcdDisplay} </small>
                  </div>
                )}

                {alaram && (
                  <div className='productcom__desc-line'>
                    <strong style={{ fontWeight: '400', fontSize: '0.8rem' , color: "#d7dadb"}}>Alaram -</strong>
                    <small> {alaram} </small>
                  </div>
                )}
                {dispensing && (
                  <div className='productcom__desc-line'>
                    <strong style={{ fontWeight: '400', fontSize: '0.8rem' , color: "#d7dadb"}}>Dispensing -</strong>
                    <small> {dispensing} </small>
                  </div>
                )}
              </Subtitle>
            </TextWrapper>
          </Column1>
        </InfoRow>
      </InfoWrapper>
    </ProductContainer>
  );
};

export default ProductComponent;

//             plate='5'
// Rated Voltage='AC 110V-240V / 50-60HZ'
// Permit Water Temperature = '5-40 C'
// coating='Titanium-Platinum Coating'
// Continuous Electrolysis Time= '30 Min'
//             phRange='3.5-10'

//             orpValue='+500~700MV'
// Alaram='Filter Replacement Alaram'
// Dispensing = 'Soft Push Button'
