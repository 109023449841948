/** @format */

import styled from 'styled-components';

export const ProductContainer = styled.div`
  color: #fff;
  //   background: ${({ lightBg }) => (lightBg ? '#f9f9f9' : '#010606')};
  background: #37517d;
  display: grid;

  justify-content: center;
  align-items: center;

  border-radius: 15px;
  box-shadow: 0px 2px 10px 0px #b0bec5;
  @media screen and (max-width: 680px) {
    height: 1005;
  }
`;

export const InfoWrapper = styled.div`
  display: grid;
  z-index: 1;

  width: 100%;
  max-width: 1100px;
  margin-right: auto;
  margin-left: auto;

  justify-content: center;
`;

export const InfoRow = styled.div`
  display: grid;
  padding: 15px;
  place-items: center;
  grid-template-areas: 'col2 col1';

  @media screen and (max-width: 768px) {
    grid-template-areas: 'col2 col2' 'col1 col1';

    place-items: center;
  }
`;

export const Column1 = styled.div`
  grid-area: col1;
`;

export const Column2 = styled.div`
  grid-area: col2;
`;

export const TextWrapper = styled.div`
  padding: 30px;
`;

export const Heading = styled.h1`
  margin-bottom: 15px;
  font-size: 1.26rem;
  font-weight: 500;
  color: '#f7f8fa';
`;

export const Subtitle = styled.p`
  max-width: 440px;

  font-size: 18px;
  color: '#010606';
`;

export const ImgWrap = styled.div`
  // position: relative;
  // width: 95%;
  // height: 300px;
`;

export const Img = styled.img`
  border-radius: 15px;
  padding-right: 0;
  width: 270px;
  height: 300px;
`;
