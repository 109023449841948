/** @format */

import React, { useState, useEffect } from 'react';
import { IconContext } from 'react-icons/lib';
import { FaBars } from 'react-icons/fa';
import { FaDownload } from 'react-icons/fa';

import {
  Nav,
  NavbarContainer,
  NavLogo,
  MobileIcon,
  NavItem,
  NavLinks,
  NavMenu,
  NavBtn,
  NavBtnLink,
  FontLogo,
} from './NavBarElements';

import { animateScroll as scroll } from 'react-scroll';

import logo from '../../Assets/logopng1.png';

const NavBar = ({ toggle }) => {
  const [scrollNav, setScrollNav] = useState(false);
  const customcolor = 1;

  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', changeNav);
    return () => window.removeEventListener('scroll', changeNav);
  }, []);

  const toggleHome = () => {
    scroll.scrollToTop();
  };

  return (
    <>
      <IconContext.Provider value={{ color: '#fff' }}>
        <Nav scrollNav={scrollNav}>
          <NavbarContainer>
            <NavLogo to='/' onClick={toggleHome}>
              <img src={logo} alt='logo' style={{ height: '55px', width: '62px' }} />
              <FontLogo style={{ fontSize: '33px', marginLeft: '12px' }}>LIVZONE</FontLogo>
            </NavLogo>

            <MobileIcon onClick={toggle}>
              <FaBars style={{ color: '#1b2b44' }} />
            </MobileIcon>

            <NavMenu>
              <NavItem>
                <NavLinks
                  to='home'
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact='true'
                  offset={-80}>
                  Home
                </NavLinks>
              </NavItem>

              <NavItem>
                <NavLinks
                  to='about'
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact='true'
                  offset={-80}>
                  About
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks
                  to='product'
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact='true'
                  offset={-80}>
                  Product
                </NavLinks>
              </NavItem>

              <NavItem>
                <NavLinks
                  to='contact'
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact='true'
                  offset={-80}>
                  Contact
                </NavLinks>
              </NavItem>
            </NavMenu>

            <NavBtn>
              <NavBtnLink to='/register'>Book Demo</NavBtnLink>
            </NavBtn>
            <NavBtn>
              <NavBtnLink customcolor={customcolor} to='/downloads'>
                Brochures
                <FaDownload
                  style={{ color: '#fff', marginLeft: '8px', height: '15px', width: '15px' }}
                />
              </NavBtnLink>
            </NavBtn>
          </NavbarContainer>
        </Nav>
      </IconContext.Provider>
    </>
  );
};

export default NavBar;
