/** @format */

import React from 'react';
import Carousel from 'react-elastic-carousel';
import './HeroSlider.css';
import { HeroContainer, HeroBg, VideoBg } from './HeroSliderElement';

import img1 from '../../Assets/webCarousel5.jpg';
import img2 from '../../Assets/web4.jpg';

const HeroSlider = () => {
  return (
    <HeroContainer>
      <Carousel enableAutoPlay autoPlaySpeed={6000} showArrows={false} infinite>
        <HeroBg>
          <VideoBg src={img2} alt='img2' />
        </HeroBg>
        <HeroBg>
          <VideoBg src={img1} alt='img1' />
        </HeroBg>
      </Carousel>
    </HeroContainer>
  );
};

export default HeroSlider;
