/** @format */

import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import ProductComponent from './ProductComponent';

import './Product.css';

import p5 from '../../Assets/5plate.jpg';
import new5plate from '../../Assets/new5_7plate.jpeg';
import new7plate from '../../Assets/new5_7plate.jpeg';
import new5plateUtc from '../../Assets/new5plate_utc.jpeg';
import new9plate from '../../Assets/new9plate.jpeg';
import new11plate from '../../Assets/new11plate.jpeg';

import p7 from '../../Assets/7plate.jpg';
import p11 from '../../Assets/plate11.jpg';
import logo from '../../Assets/logopng1.png';

import Footer from '../footer/Footer';
import { NavLogo } from '../navBar/NavBarElements';

const Product = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <>
      <div className='product'>
        <div className='product__home'>
          <NavLogo to='/'>
            <img src={logo} alt='logo' style={{ height: '55px', width: '62px' }} />
          </NavLogo>
        </div>
        <div className='product__title'>Our Products</div>

        <div className='product__cardWrapper'>
          <div className='product__description'>
            <p className='product__description_p'>
              LIVZONE Water Industries is pleased to introduce Alkaline Water Ionizers for
              revitalizing, refreshing, pure clean & healthy Alkaline water for you and your
              precious family.Alkaline Water Ionizers gives you full control to set mineral level as
              well as the pH level of water only with just one touch.
            </p>
          </div>

          <div className='plate_type'>
            <strong className='plate_type_heading'>5 Plate Ionizer</strong>

            <div style={{ marginBottom: 30 }}>
              <ProductComponent
                heading='5 Plate Water Ionizer'
                img={p5}
                alt='p51'
                plate='5'
                phRange='4-10'
                coating='Titanium-Platinum Coating'
                permitWaterTemperature='5-40'
                electrolysisTime='30 Min'
                orpValue='600MV'
                ratedVoltage='220V-250V / 50HZ'
              />
            </div>
{/* Product are no longer available */}
            {/* <ProductComponent
              heading='5 Plate Water Ionizer'
              img={new5plate}
              alt='p52'
              plate='5'
              ratedVoltage='AC 110V-240V / 50-60HZ'
              permitWaterTemperature='5-40'
              coating='Titanium-Platinum Coating'
              electrolysisTime='30 Min'
              phRange='4-10.5'
              orpValue='+500~600MV'
              lcdDisplay='YES'
              dispensing='One Touch Dispensing'
            /> */}
          </div>

          <div className='plate_type'>
            <strong className='plate_type_heading'>7 Plate Ionizer</strong>

            <div style={{ marginBottom: 30 }}>
              <ProductComponent
                heading='7 Plate Water Ionizer'
                img={p7}
                alt='p71'
                plate='7'
                phRange='4-10.5'
                permitWaterTemperature='5-40'
                electrolysisTime='30 Min'
                coating='Titanium-Platinum Coating'
                orpValue='700MV'
                ratedVoltage='220V-250V / 50HZ'
              />
            </div>
            {/* <ProductComponent
              heading='7 Plate Water Ionizer'
              img={new7plate}
              alt='p72'
              plate='7'
              ratedVoltage='AC 110V-240V / 50-60HZ'
              permitWaterTemperature='5-40'
              coating='Titanium-Platinum Coating'
              electrolysisTime='30 Min'
              phRange='4-10.5'
              orpValue='+500~600MV'
              lcdDisplay='YES'
              dispensing='One Touch Dispensing'
            /> */}
          </div>

          <div className='plate_type'>
            <strong className='plate_type_heading'>11 Plate Ionizer</strong>

            <div style={{ marginBottom: 30 }}>
              <ProductComponent
                heading='11 Plate Water Ionizer'
                img={p11}
                alt='p3'
                plate='11'
                phRange='2.8-11'
                coating='Titanium-Platinum Coating'
                electrolysisTime='30 Min'
                orpValue='800MV'
                ratedVoltage='AC 110V-240V / 50-60HZ'
              />
            </div>
            {/* <ProductComponent
              heading='11 Plate Water Ionizer'
              img={new11plate}
              alt='p11'
              plate='11'
              ratedVoltage='AC 110V-240V / 50-60HZ'
              permitWaterTemperature='5-40'
              coating='Titanium-Platinum Coating'
              electrolysisTime='30 Min'
              phRange='2.8-11'
              orpValue='+500~800MV'
              lcdDisplay='YES'
              dispensing='One Touch Dispensing'
            /> */}
          </div>

          <div className='plate_type'>
            <strong className='plate_type_heading'>9 Plate Ionizer</strong>
            <ProductComponent
              heading='9 Plate Water Ionizer'
              img={new9plate}
              alt='p9'
              plate='9'
              ratedVoltage='AC 110V-240V / 50-60HZ'
              permitWaterTemperature='5-40'
              coating='Titanium-Platinum Coating'
              electrolysisTime='30 Min'
              phRange='3.0-11.2(Max)'
              orpValue='+500~700MV'
              lcdDisplay='YES'
              dispensing='One Touch Dispensing'
            />
          </div>

          {/* <div className='plate_type'>
            <strong className='plate_type_heading'>5 Plate (Under The Sink)</strong>
            <ProductComponent
              img={new5plateUtc}
              heading='5 Plate Under the sink'
              alt='p5uts'
              plate='5'
              ratedVoltage='AC 110V-240V / 50-60HZ'
              permitWaterTemperature='5-40'
              coating='Titanium-Platinum Coating'
              electrolysisTime='30 Min'
              phRange='3.5-10'
              orpValue='+500~700MV'
              alaram='Filter Replacement Alaram'
              dispensing='Soft Push Button'
            />
          </div> */}
        </div>

        <div className='product__button'>
          <Link to='/register'>
            <button className='product__button-order'>Book Now!</button>
          </Link>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Product;
