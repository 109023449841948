/** @format */

import React, { useState } from 'react';
import { homeObjOne, homeObjThree } from '../../components/utils/Data';
import Footer from '../../components/footer/Footer';
import './Home.css';
import HeroSection from '../../components/HeroSection/HeroSection';
import InfoSection from '../../components/infoSection/InfoSection';
import NavBar from '../../components/navBar/NavBar';
import ServiceSection from '../../components/service/ServiceSection';
import SideBarNav from '../../components/sideNav/SideBarNav';
import HeroSlider from '../../components/slider/HeroSlider';

import Card from '../../components/card/Card';
import { FaFacebook, FaInstagram, FaYoutube } from 'react-icons/fa';

const Home = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <SideBarNav isOpen={isOpen} toggle={toggle} />
      <NavBar toggle={toggle} />

      <HeroSlider />

      <HeroSection />
      <InfoSection {...homeObjOne} />
      <InfoSection {...homeObjThree} />
      <Card />
      <ServiceSection />

      <div className='SocialMedia'>
        <div className='SocialIcons'>
          <a
            className='SocialIconLink'
            href='https://www.facebook.com/107201314020266/posts/128819811858416/?sfnsn=scwshmo'
            aria-label='facebook'>
            <FaFacebook />
          </a>

          <a
            className='SocialIconLink'
            href='https://www.instagram.com/livzone_india/'
            aria-label='instagram'>
            <FaInstagram />
          </a>

          <a
            className='SocialIconLink'
            href='https://www.youtube.com/watch?v=0AzKR2K-gKQ&feature=youtu.be'
            aria-label='youtube'>
            <FaYoutube />
          </a>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Home;
