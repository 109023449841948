/** @format */

import styled from 'styled-components';

export const HeroContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const HeroBg = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  border-radius: 50px;
  margin: auto;
`;

export const VideoBg = styled.img`
  width: 100%;

  border-radius: 10px;
`;
