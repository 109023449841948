/** @format */

import React from 'react';
import RegisterUser from '../components/registerUser/RegisterUser';

const RegisterUserPage = () => {
  return (
    <div>
      <RegisterUser />
    </div>
  );
};

export default RegisterUserPage;
