/** @format */

import React from 'react';
import Product from '../components/product/Product';

const ProductPage = () => {
  return (
    <>
      <Product />
    </>
  );
};

export default ProductPage;
