/** @format */

import React from 'react';

import './Card.css';

const Card = () => {
  return (
    <div className='card__layer1' id='product'>
      <div className='card__layer2'>
        <div className='card__headers'>
          <div className='card__subheading'>
            <p>WHAT YOU NEED TO KNOW?</p>
          </div>

          <div className='card__heading'>
            <h2>Benefits of alkaline water</h2>
          </div>
        </div>
        <div className='card__cards'>
          <div className='card__actual'>
            <div className='card__title'>Maintain Ph level</div>

            <div className='card__content'>
              Alkaline water ionizer maintain the ph value of water, and helps to improve overall
              health.
            </div>
          </div>

          <div className='card__actual'>
            <div className='card__title'>Anti-Oxidant</div>

            <div className='card__content'>
              To maximize the benefits of these two, start eating antioxidant-rich foods and
              drinking alkaline water now.
            </div>
          </div>

          <div className='card__actual'>
            <div className='card__title'>Cancer Resistant</div>

            <div className='card__content'>
              Alkaline water prevents our body from various types cancer and acts as a cancer
              resistant.
            </div>
          </div>

          <div className='card__actual'>
            <div className='card__title'>Boost Immunity</div>

            <div className='card__content'>
              <p>Ionized water not only is pure and healthy ,but its also increase our immunity.</p>
            </div>
          </div>

          <div className='card__actual'>
            <div className='card__title'>Heart Health</div>

            <div className='card__content'>
              <p>
                Alkaline water ionizer also adds magnesium and calcium to the water, which is
                beneficial for heart health.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
