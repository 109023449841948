/** @format */

import React from 'react';

import {
  FooterContainer,
  FooterWrap,
  FooterLinksWrapper,
  FooterLinksContainer,
  FooterLinkItems,
  FooterLinkTitle,
  SocialIconLink,
  SocialIcons,
  SocialLogo,
  SocialMedia,
  WebsiteRights,
  SocialMediaWrap,
} from './FooterElements';
import iso_9001 from '../../Assets/iso_9001.png';
import iso_13485 from '../../Assets/iso_13485.png';

import './Footer.css';

import {
  FaFacebook,
  FaInstagram,
  FaYoutube,
  FaEnvelope,
  FaMapMarkerAlt,
} from 'react-icons/fa';

const Footer = () => {
  return (
    <FooterContainer id='contact'>
      <FooterWrap>
        <FooterLinksContainer>
          <FooterLinksWrapper>
            <FooterLinkItems>
              <FooterLinkTitle> Contact Us</FooterLinkTitle>

              <div className='contact__items'>
                <div className='contact__items_icon'>
                  <FaMapMarkerAlt />
                </div>
                <strong style={{ fontSize: '0.9rem' }}>Branch Office</strong>
                <small>108, First Floor Orbit Tower, Sundar Nagar Chowk, Raipur Chhattisgarh</small>
              </div>

              <div className='contact__items'>
                <strong style={{ marginLeft: '30px', fontSize: '0.86rem' }}>Head Office</strong>
                <small>Head office m 707 , Raj Nagar extension, ghaziabad, New Delhi</small>
              </div>

              <div className='contact__items'>
                <div className='contact__items_icon'>
                  <FaEnvelope />
                </div>
                <small>livzonewi@gmail.com</small>
              </div>

              {/* <div className='contact__items'>
                <div className='contact__items_icon'>
                  <FaPhone />
                </div>
                <small>+91-7999950973 </small>
              </div> */}
            </FooterLinkItems>
          </FooterLinksWrapper>
        </FooterLinksContainer>
      </FooterWrap>

      <SocialMedia>
        <div className='certificates'>
          <h2>CERTIFICATES :</h2>
          <div className='certi_images'>
            <img src={iso_9001} alt='logo1' height='70px' width='70px' />
            <img src={iso_13485} alt='logo2' height='70px' width='70px' />
          </div>
        </div>

        <SocialMediaWrap>
          <SocialLogo to='/'>LIVZONE</SocialLogo>
          <WebsiteRights>
            Copyright LivZone © {new Date().getFullYear()} All rights reserved.
          </WebsiteRights>

          <SocialIcons>
            <SocialIconLink
              href='https://www.facebook.com/107201314020266/posts/128819811858416/?sfnsn=scwshmo'
              target='_blank'
              aria-label='facebook'>
              <FaFacebook />
            </SocialIconLink>

            <SocialIconLink
              href='https://www.instagram.com/livzone_india/'
              target='_blank'
              aria-label='instagram'>
              <FaInstagram />
            </SocialIconLink>

            <SocialIconLink
              href='https://www.youtube.com/watch?v=0AzKR2K-gKQ&feature=youtu.be'
              target='_blank'
              aria-label='youtube'>
              <FaYoutube />
            </SocialIconLink>
          </SocialIcons>
        </SocialMediaWrap>
      </SocialMedia>
    </FooterContainer>
  );
};

export default Footer;
