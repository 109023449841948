/** @format */

import React from 'react';

import { ServicesContainer, ServicesH1, ServicesWrapper } from './ServiceElements';

const ServiceSection = () => {
  return (
    <ServicesContainer>
      <ServicesH1>Benefits Of Hydrogen In Alkaline Water</ServicesH1>
      <ServicesWrapper>
        <strong>Hydrogen the fuel of life</strong>
        <small>
          Nobe Prize winner Albert Szent-Gyorgyi said his 1937 Nobel Lecture, "A living cell
          requiure energy not only for all its functions, but also for the maintenance of its
          structure"..."bring out the fact that our body really only knows one fuel,
        </small>

        <small>
          Hydrogen water is regular water with hydrogen gas added to the water. According to some
          resources, adding hydrogen gas to the water increases its anti-inflammatory and
          antioxidant properties. It's been touted for its ability to increase energy, slow down the
          aging process, and improve muscle recovery after a workout.
        </small>
      </ServicesWrapper>
    </ServicesContainer>
  );
};

export default ServiceSection;
