/** @format */

import React, { useState } from 'react';

import { Button } from '../utils/ButtonElements';
import {
  HeroContainer,
  HeroBg,
  VideoBg,
  HeroContent,
  HeroH1,
  HeroP,
  HeroBtnWrapper,
  ArrowForward,
  ArrowRight,
} from './HeroElements';
import Video from '../../Assets/darkWbg.jpg';

const HeroSection = () => {
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(!hover);
  };

  return (
    <HeroContainer id='home'>
      <HeroBg>
        <VideoBg src={Video} />
      </HeroBg>
      <HeroContent>
        <HeroH1>Livzone Water Ionizer </HeroH1>

        <HeroP>
          LIVZONE Water Industries is pleased to introduce Alkaline Water Ionizers for revitalizing,
          refreshing, pure clean & healthy Alkaline water for you and your precious family.Alkaline
          Water Ionizers gives you full control to set pH level of water only with just one
          touch.These are some things which can be maintained in your body by drinking alkaline
          water.
        </HeroP>

        <HeroBtnWrapper>
          <Button
            to='product'
            onMouseEnter={onHover}
            onMouseLeave={onHover}
            primary='true'
            dark='true'>
            Get started {hover ? <ArrowForward /> : <ArrowRight />}
          </Button>
        </HeroBtnWrapper>
      </HeroContent>
    </HeroContainer>
  );
};

export default HeroSection;
