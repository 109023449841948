/** @format */

import React from 'react';
import './App.css';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Home from './Pages/Home/Home';
import RegisterUserPage from './Pages/RegisterUserPage';
import ProductPage from './Pages/ProductPage';
import FileDownloadPage from './Pages/fileDownload/FileDownloadPage';

function App() {
  return (
    <Router>
      <Switch>
        <Route path='/' component={Home} exact />
        <Route path='/register' component={RegisterUserPage} exact />
        <Route path='/product' component={ProductPage} exact />
        <Route path='/downloads' component={FileDownloadPage} exact />
      </Switch>
    </Router>
  );
}

export default App;
